import { ColaboradorModel } from '@/store/catalogos/colaborador.model';
import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Util } from '@/utils/util';
import { AfterViewInit, Component, HostBinding, OnInit, Renderer2,  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdmSeleccionarSucursalComponent } from '@modules/admin/adm-seleccionar-sucursal/adm-seleccionar-sucursal.component';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu;
    sesion:ColaboradorModel;
    autoOcultarMenu:boolean;

    constructor(
         public appService: AppService, private store: Store<AppState>,
         private apiService: ApiService, private toastr: ToastrService
        ,public dialog: MatDialog, private router: Router
        ,private util: Util,private renderer: Renderer2) {
    }

    mostrarSelectorSucursal(){
        let rowCopy = Object.assign({}, this.util.getSesion());
        const dialogRef = this.dialog.open(AdmSeleccionarSucursalComponent, {
        width: '500px', data: {tipo:rowCopy.tipo ,row:rowCopy} 
        });
        console.log('MOSTRAR MODAL',this.util.getSesion());
        dialogRef.afterClosed().subscribe(result => {
            if (result?.success) {
                this.sesion=this.util.getSesion();
                this.sesion.sucursal = result.data;
                this.util.setSesion(this.sesion);
                location.reload();
            }
        });
    }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        this.loadMenu();
        this.autoOcultarMenu = JSON.parse(localStorage.getItem("autoOcultarMenu"));

        this.sesion = this.util.getSesion();
        switch(this.sesion.tipo){
            case 0:
                this.menu =MENU_1;
                break;
            case 1:
                this.menu =MENU_1;
                break;
            case 2:
                this.menu = MENU_2;
                break;
            default:
                break;
        }
    }

    ngAfterViewInit(){
        this.sesion = this.util.getSesion();
        switch(this.sesion.tipo){
            case 0:
                let cambioSucursal=sessionStorage.getItem("sucursalCambiada");
                const THIS=this;
                if(this.util.isEmpty(cambioSucursal)){
                    setTimeout(function(){
                        sessionStorage.setItem('sucursalCambiada','1');
                        THIS.mostrarSelectorSucursal();
                    },500);
                }
                break;
            case 1:
                break;
            case 2:
                break;
            default:
                break;
        }
    }

    async loadMenu() {
        this.apiService.menu().subscribe(response => {
            this.menu = response;
            localStorage.setItem("menu", response.toString());
        }, error => {
            
        });
    }

    ocultarMenu(){
        this.util.ocultarMenu(this.renderer);
    }

    changeOcultarMenu(){
        console.log("autoOcultarMenu ", this.autoOcultarMenu);
        localStorage.setItem("autoOcultarMenu",this.autoOcultarMenu+"");
        this.util.autoOcultarMenu(this.renderer);
    }

}

export const MENU_1 = [
    {
        name: 'Administrador',
        iconClasses: 'fas fa-tachometer-alt',
        children: [
            {
                name: 'Empresa',
                iconClasses: 'far fa-building',
                path: ['/catalogos/empresa']
            },
            {
                name: 'Sucursal',
                iconClasses: 'far fa-building',
                path: ['/catalogos/sucursal']
            },
            {
                name: 'Responsable Sucursal',
                iconClasses: 'far fa-address-book',
                path: ['/catalogos/responsable-sucursal']
            },
            {
                name: 'Escolaridad',
                iconClasses: 'far fa-address-book',
                path: ['/catalogos/escolaridad']
            },
            {
                name: 'Puesto',
                iconClasses: 'far fa-briefcase',
                path: ['/catalogos/puesto']
            },
            {
                name: 'Tipo Pago',
                iconClasses: 'far fa-credit-card-alt',
                path: ['/catalogos/tipo-pago']
            },
            {
                name: 'Descuentos',
                iconClasses: 'far fa-percent',
                path: ['/catalogos/descuento']
            },
            {
                name: 'Grupos',
                iconClasses: 'far fa-address-book',
                path: ['/adm/grupo']
            },
            {
                name: 'Parametro',
                iconClasses: 'far fa-address-book',
                path: ['/adm/parametros']
            }

        ]
    },
    {
        name: 'Catalogos',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Colaboradores',
                iconClasses: 'fas fa-users',
                path: ['/catalogos/colaborador']
            },
            {
                name: 'Especialidad',
                iconClasses: 'far fa-graduation-cap',
                path: ['/catalogos/especialidad']
            },
            {
                name: 'Certificación',
                iconClasses: 'far fa-certificate',
                path: ['/catalogos/certificacion']
            },
            {
                name: 'Producto',
                iconClasses: 'far fa-address-book',
                path: ['/catalogos/producto']
            },
            {
                name: 'Existencia Producto',
                iconClasses: 'far fa-circle',
                path: ['/catalogos/existencia-producto']
            },
            {
                name: 'Alumnos',
                iconClasses: 'far fa-user',
                path: ['/catalogos/alumno']
            }
        ]
    },
    {
        name: 'Proceso',
        iconClasses: 'far fa-folder',
        children: [
            {
                name: 'Incripcion',
                iconClasses: 'fas fa-users',
                path: ['adm/inscripcion']
            },
            {
                name: 'Cobro',
                iconClasses: 'fas fa-dollar',
                path: ['adm/cobro']
            },
            {
                name: 'Cancelar Pago',
                iconClasses: 'fas fa-dollar',
                path: ['adm/cancelar-pago/0']
            },
            {
                name: 'Catalogo Cursos',
                iconClasses: 'fas fa-list-check',
                path: ['adm/catalogo-cursos']
            },
            {
                name: 'Cancelar Inscripción',
                iconClasses: 'fas fa-list-check',
                path: ['adm/cancelar-inscripcion']
            },
            {
                name: 'Ajustar Colegiatura',
                iconClasses: 'fas fa-dollar',
                path: ['adm/ajuste-colegiatura']
            }
        ]
    },
    {
        name: 'Reportes',
        iconClasses: 'far fa-folder',
        children: [
            {
                name: 'Pagos',
                iconClasses: 'fas fa-dollar',
                path: ['reporte/pagos']
            },
            {
                name: 'Pagos Atrasados y Proximos',
                iconClasses: 'fas fa-dollar',
                path: ['reporte/pagos-proximos']
            },
            {
                name:'Alumnos por Grupo',
                iconClasses: 'fa fa-list',
                path:['reporte/alumnos-grupo']
            }
        ]
    },
    {
        name: 'Estudiantes',
        iconClasses: 'far fa-folder',
        children: [
            {
                name: 'Registrar Asistencia',
                iconClasses: 'fas fa-student',
                path: ['students/attendance-check']
            }
        ]
    }
];

export const MENU_2 = [
    {
        name: 'Proceso',
        iconClasses: 'far fa-folder',
        children: [
            {
                name: 'Incripcion',
                iconClasses: 'fas fa-users',
                path: ['adm/inscripcion']
            },
            {
                name: 'Cobro',
                iconClasses: 'fas fa-users',
                path: ['adm/cobro']
            },
            {
                name: 'Catalogo Cursos',
                iconClasses: 'fas fa-list-check',
                path: ['adm/catalogo-cursos']
            }
        ]
    }
];
