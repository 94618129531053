import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { AdmTicketPagoComponent } from '@modules/admin/adm-ticket-pago/adm-ticket-pago.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            },
            {
              path: 'setting',
              loadChildren:() => import ('./modules/setting/setting.module').then(m=>m.SettingModule)
            },
            {
              path: 'adm',
              loadChildren:() => import ('./modules/admin/adm.module').then(m=>m.AdmModule)
            },
            {
              path: 'catalogos',
              loadChildren:() => import ('./modules/catalogos/catalogos.module').then(m=>m.CatalogosModule)
            },
            {
              path: 'reporte',
              loadChildren:() => import ('./modules/reportes/reportes.module').then(m=>m.ReportesModule)
            },
            {
              path: 'students',
              loadChildren:() => import ('./modules/students/students.module').then(m=>m.StudentsModule)
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'ticket/:folio',
        component: AdmTicketPagoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'ticket/:folio/:origen',
        component: AdmTicketPagoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
