import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[inputPorcentaje]'
})
export class PorcentajeDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this._el.nativeElement.value;
    let val = initalValue.replace(/[^0-9.]*/g, '');
    if(!isNaN(val)){
        if(Number(val)>100){
        let t = val+"";
        val = val.substring(0,t.length - 1);
        }else if(Number(val)<0){
        val = 0;
        }
    }
    this._el.nativeElement.value = val;
    if (initalValue !== this._el.nativeElement.value) {
        event.stopPropagation();
    }
  }


}
